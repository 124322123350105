import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { H4, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { DatePicker } from "./date-picker";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Date picker",
	notepad: "https://hackmd.io/Pvl87_7pRByOfSnj6A9fbg",
};

const translations = {
	nextMonthAriaLabel: "Next month",
	nextYearAriaLabel: "Next year",
	nextCenturyAriaLabel: "Next century",
	nextDecadeAriaLabel: "Next decade",
	prevMonthAriaLabel: "Previous month",
	prevYearAriaLabel: "Previous year",
	prevCenturyAriaLabel: "Previous century",
	prevDecadeAriaLabel: "Previous decade",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Date picker"
			subTitle="Enable users to select a single date from a visual calendar interface."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={DatePicker} />
				<DocsTable>
					<thead>
						<tr>
							<th>Components</th>
							<th>Purpose</th>
							<th>Usage guideline</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Date Picker Component</td>
							<td>
								Provide a highly customizable date picker that seamlessly integrates with the
								application's design and functionality.
							</td>
							<td>
								Consider when customization, advanced features, specific user interactions, or
								complex data handling are crucial.
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Forms and input/Input Field#usage-with-date">
									Browser-owned Date Picker
								</GatsbyLink>{" "}
							</td>
							<td>
								Offer a familiar, accessible, and cross-browser compatible date picker with minimal
								development effort.
							</td>
							<td>
								Recommended for most use cases involving basic date selection where extensive
								customization and advanced features are not required.
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Date pickers simplify date input, making it especially useful for scheduling reports or
					filtering data. They offer a user-friendly alternative to manual text entry, reducing
					errors and ensuring consistent formatting.
				</Paragraph>
				<Paragraph>The Date picker consists of:</Paragraph>
				<Ul
					items={[
						<>
							<b>Calendar view</b>: A visual representation of a month or multiple months, allowing
							users to navigate and select a date.
						</>,
						<>
							<b>Navigation controls</b>: Arrows or buttons to move between months or years.
						</>,
						<>
							<b>Today button</b>: Quickly selects the current date.{" "}
						</>,
					]}
				/>
				<Header.H3>Basic usage</Header.H3>
				<Example fn={BasicUsage} />
				<Header.H2>Properties</Header.H2>
				<Header.H3>DatePicker</Header.H3>
				<Knobs
					component={DatePicker}
					initialProps={({ setState }) => ({
						value: new Date(),
						onChange: (item) => setState({ value: item }),
						translations,
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>DatePicker</Code> when
					</Paragraph>
					<Ul
						items={[
							"Users need to select a specific date.",
							"Precise date input is required, reducing ambiguity.",
							"The application requires a consistent date format.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>DatePicker</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								{" "}
								<GatsbyLink to="/lab/components/Forms and input/Forms">Forms</GatsbyLink> : For
								scheduling future events or automating report delivery.
							</>,
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Table toolbar">
									Table Toolbar
								</GatsbyLink>{" "}
								/<GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink> : To filter
								data by date.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your{" "}
								<Code>DatePicker</Code> to existing components for visual consistency.
							</>,
							<>
								Since the <Code>DatePicker</Code> doesn't have a built-in input field, always pair{" "}
								it with an{" "}
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
								component to display the selected date and trigger the calendar view. Ensure a clear
								visual connection between the selected dates in the calendar and the Input Field's
								display for a seamless user experience.
							</>,
							<>
								Use the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form element wrapper
								</GatsbyLink>{" "}
								component to restrict date selections and display clear error messages for invalid
								choices (e.g., future dates).
							</>,
							<>
								Date formats can be customized and often vary based on user location. To avoid
								confusion, select a single date format and use it consistently throughout your
								interface.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Users need to input a range of dates. Use{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Period picker">
									Period Picker
								</GatsbyLink>{" "}
								instead.
							</>,
							"The application requires flexible date formats or natural language input.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul items={[<>Clear labels and instructions.</>]} />
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								<b>Labels</b>: Keep labels clear and concise (e.g., "Select Date").
							</>,
							<>
								<b>Placeholder</b>: Use a format example as the placeholder (e.g., "MM/DD/YYYY").
							</>,
							<>
								<b>Helper text</b>: Briefly explain interaction options (e.g., "Click calendar or
								type directly into this field").
							</>,
							<>
								<b>Error messages</b>: Clearly state the issue and expected format (e.g., "Invalid
								date. Use MM/DD/YYYY").
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 69.0.0</Header.H3>
				<Paragraph>
					The <Code>DatePickerWithInput</Code> component has been removed. Use{" "}
					<GatsbyLink to="/lab/components/Forms%20and%20input/Input%20Field#usage-with-date">
						Input Field
					</GatsbyLink>{" "}
					with <Code>type="date"</Code> instead.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const [value, setValue] = useState(new Date(2000, 1, 1));
	return (
		<DatePicker value={value} onChange={(d) => setValue(d as Date)} translations={translations} />
	);
};
